import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import { UnAuthRedirect } from '@uz/unitz-tool-components/AppRedirect';
import LoadingScreen from '@uz/unitz-components-web/LoadingScreen';
import _ from 'lodash';
import { Row, Col } from 'antd';
import CourseTemplateBrief from '@uz/unitz-tool-components/CourseTemplateBrief';
import gstyles from '@vl/gstyles';
import CourseFormatter from '@uz/unitz-models/CourseModel/formatter';
import CourseReportAttendees from '@uz/unitz-tool-components/CourseReportAttendees';

const Index = () => {
  // @TODO: validate missing required input
  const statusStyle = {
    draft: gstyles.colors.powerPeony,
    upcoming: gstyles.colors.powerPeony,
    ongoing: gstyles.colors.yellow500,
    finished: gstyles.colors.brandb500,
    completed: gstyles.colors.brandb500,
  };

  return (
    <DIV className="pageContainer">
      <UnAuthRedirect to={ctx.apply('authModel.generateLoginRedirectUrl')}>
        <DIV className="isLoading">
          <div className="fixed flex justify-center items-center w-full h-full top-0 left-0 select-none bg-white200">
            <LoadingScreen />
          </div>
        </DIV>
        <DIV className="notLoading">
          <div className="p-4 h-full">
            <div className="p-4 bg-white500 rounded-lg shadow-xl">
              <div className="text-center font-semibold text-2xl">
                {ctx.apply('i18n.t', 'ZoomToolCourse.course_report')}
              </div>
              <div className="mb-2 text-main font-semibold text-2xl">
                {ctx.apply('i18n.t', 'ZoomToolCourse.course')}: {ctx.get('@item.name') ? ctx.get('@item.name') : ''}
              </div>
              <CourseTemplateBrief data={ctx.get('@item')} />
              <Row className="p-4">
                <Col className="border-r-4" xs={24} md={8}>
                  <div className="text-xl font-semibold text-sub mb-2">
                    {ctx.apply('i18n.t', 'ZoomToolCourse.Table.startDate')}:{' '}
                    {CourseFormatter.startAt(ctx)(ctx.get('@item'))}
                  </div>
                  <div className="flex items-center space-x-2">
                    <div className="text-xl font-semibold text-sub">
                      {ctx.apply('i18n.t', 'ZoomToolMember.Table.status.col_name')}:
                    </div>
                    <div
                      style={{ backgroundColor: _.get(statusStyle, _.get(ctx.get('@item'), 'status', ''), '') }}
                      className={`capitalize inline-block text-xs font-semibold text-white500 rounded-lg text-center px-2 py-1`}
                    >
                      {ctx.apply('i18n.t', `ZoomToolCourse.Status.${_.get(ctx.get('@item'), 'status', '')}`)}
                    </div>
                  </div>
                </Col>
                <Col className="border-r-4" xs={24} md={8}>
                  <div className="flex items-center space-x-2">
                    <div className="text-xl font-semibold text-sub">
                      {ctx.apply('i18n.t', 'ZoomToolCourse.Table.teacher')}:
                    </div>
                    <div className="text-xl font-semibold text-brandb500 underline">
                      {_.compact([
                        _.get(ctx.get('@item.course_teacher.0'), 'member.member_profile.first_name'),
                        _.get(ctx.get('@item.course_teacher.0'), 'member.member_profile.last_name'),
                      ]).join(' ')}
                    </div>
                  </div>
                </Col>
                <Col className="border-r-4" xs={24} md={8}>
                  <div className="flex flex-col space-y-2">
                    <div className="text-xl font-semibold text-sub">
                      {ctx.apply('i18n.t', 'ZoomToolCourse.number_of_students')}:{' '}
                      {ctx.get('@item.course_room_attendees.length')}
                    </div>
                    <div className="text-xl font-semibold text-sub">
                      {ctx.apply('i18n.t', 'ZoomToolCourse.number_of_lessons')}: {ctx.get('@item.course_rooms.length')}
                    </div>
                    <div className="text-xl font-semibold text-sub">
                      {ctx.apply('i18n.t', 'ZoomToolCourse.number_of_hours')}:{' '}
                      {ctx.apply(
                        'durationModel.formatDuration',
                        ctx.get('@item.course_rooms.length') * ctx.get('@item.session_duration')
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="my-6">
                <CourseReportAttendees />
              </div>
            </div>
          </div>
        </DIV>
      </UnAuthRedirect>
    </DIV>
  );
};

export default displayName(Index);
