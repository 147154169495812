import React from 'react';
import { bindings, hook } from '@vl/redata';
import useLoadingModel from '@vl/hooks/useLoadingModel';
import useRoute from '@vl/hooks/useGbRouteDe';
import { getClient, gql } from '@vl/mod-clients/hasuraApp';
import _ from 'lodash';
import useObservableSource from '@vl/hooks/useObservableSource';
import useSubjectSource from '@vl/hooks/useSubjectSource';

const bindData = bindings({
  pageContainer: {
    rules: [
      [
        'data',
        {
          data: {
            loadingModel: hook(useLoadingModel(() => ({ fetchTemplate: false }))),
            getDataParams: hook(() => {
              const route = useRoute();
              const paramsData = _.get(route, 'params.id', '');
              return paramsData;
            }),

            durationModel: hook((ctx) => {
              function parseDuration(duration) {
                let remain = duration;

                const totalMinutes = Math.floor(remain / 60);

                let hours = Math.floor(totalMinutes / 60);

                let minutes = Math.floor(remain / 60);

                let seconds = Math.floor(remain % 60);

                let milliseconds = remain;
                return {
                  hours,
                  minutes: totalMinutes < 60 ? minutes : 0,
                  seconds,
                  milliseconds,
                };
              }

              function formatTime(o, useMilli = false) {
                let parts = [];
                if (o.hours) {
                  let ret = ctx.apply('i18n.t', 'ZoomToolCourse.Report.hour', { count: o.hours });
                  parts.push(ret);
                }
                if (o.minutes) {
                  let ret = ctx.apply('i18n.t', 'ZoomToolCourse.Report.minute', { count: o.minutes });
                  parts.push(ret);
                }
                if (o.seconds) {
                  let ret = ctx.apply('i18n.t', 'ZoomToolCourse.Report.second', { count: o.seconds });
                  parts.push(ret);
                }
                if (useMilli && o.milliseconds) {
                  let ret = o.milliseconds + ' millisecond';
                  if (o.milliseconds !== 1) {
                    ret += 's';
                  }
                  parts.push(ret);
                }
                if (parts.length === 0) {
                  return ctx.apply('i18n.t', 'ZoomToolCourse.Report.instantly');
                } else {
                  return parts.join(' ');
                }
              }
              return {
                formatDuration: (duration, useMilli = false) => {
                  let time = parseDuration(duration);
                  return formatTime(time, useMilli);
                },
              };
            }),

            '@item': hook((ctx) => {
              const course_id = ctx.get('getDataParams');
              const sub = useObservableSource(
                async () => {
                  try {
                    if (!course_id) return null;
                    const client = await getClient();
                    const data = await client.subscribe(
                      gql`
                        subscription GetCourseInfo($id: uuid!) {
                          b2b_course_by_pk(id: $id) {
                            id
                            name
                            description
                            photo_url
                            type
                            session_duration
                            account_id
                            materials
                            start_at
                            link_urls
                            status
                            course_teacher {
                              role
                              user_id
                              member {
                                id
                                member_profile {
                                  first_name
                                  last_name
                                  email
                                  avatar_url
                                }
                              }
                            }
                            course_room_attendees {
                              id
                              user_id
                              member {
                                id
                                member_profile {
                                  first_name
                                  last_name
                                  email
                                  avatar_url
                                }
                              }
                            }
                            course_rooms(order_by: { start_at: asc }) {
                              id
                              name
                              photo_url
                              description
                              start_at
                              end_at
                              status
                              link_urls
                              materials
                              materials
                              course_room_attendees {
                                id
                                member {
                                  id
                                  user_id
                                  member_profile {
                                    first_name
                                    last_name
                                    email
                                    avatar_url
                                  }
                                }
                              }
                              course {
                                course_teacher {
                                  role
                                  user_id
                                  member {
                                    id
                                    member_profile {
                                      first_name
                                      last_name
                                      email
                                      avatar_url
                                    }
                                  }
                                }
                              }
                            }
                            course_room_attendees {
                              id
                              member {
                                id
                                user_id
                                member_profile {
                                  first_name
                                  last_name
                                  email
                                  avatar_url
                                }
                              }
                            }
                          }
                        }
                      `,
                      { id: course_id }
                    );
                    return { observe: () => data };
                  } catch (err) {
                    console.log('error', err);
                  }
                },
                null,
                [course_id]
              );
              const data = useSubjectSource(sub);
              return _.get(data, 'data.b2b_course_by_pk', {});
            }),
          },
        },
      ],
    ],
  },
  isLoading: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => {
            return _.isEmpty(ctx.get('@item'));
          }),
        },
      ],
    ],
  },
  notLoading: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => !_.isEmpty(ctx.get('@item'))),
        },
      ],
    ],
  },
});

export default bindData;
