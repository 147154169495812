import React from 'react';
import { bindings, hook } from '@vl/redata';
import _ from 'lodash';
import useLocalStorage from '@vl/hooks/useLocalStorageWeb';
import PaginationModel from '@uz/unitz-models/PaginationModel';
import B2BCourseModel from '@uz/unitz-models/B2BCourseModel';
import CourseFormatter from '@uz/unitz-models/CourseModel/formatter';
import useRoute from '@vl/hooks/useGbRouteDe';
import MemberFormatter from '@uz/unitz-models/B2BMemberModel/formatter';
import moment from 'moment';
import gstyles from '@vl/gstyles';
import i18n from 'i18n-js';
import { START_TIME_WITHOUT_DAY_OF_WEEK, TIME_HOUR_MINUTE_ML_FORMAT } from '@vl/mod-utils/datetime';
import { Avatar } from '@uz/unitz-components-web/Avatar';
import { getClient, gql } from '@vl/mod-clients/hasuraApp';
import usePromiseSource from '@vl/hooks/usePromiseSource';

const bindData = bindings({
  component: {
    rules: [
      [
        'data',
        {
          data: {
            courseLessons: hook((ctx) => {
              const course_id = _.get(useRoute(), 'params.id', '');
              const account_id = ctx.apply('accountModel.getAccountId');
              const data = usePromiseSource(
                async () => {
                  try {
                    if (!course_id) return null;
                    const client = await getClient();
                    const course_data = await client.request(
                      gql`
                        query GetCourseLesson($account_id: uuid = "", $course_id: uuid = "") {
                          b2b_course_room(
                            where: { course_id: { _eq: $course_id }, account_id: { _eq: $account_id } }
                            limit: 4
                            order_by: { start_at: asc }
                          ) {
                            id
                            name
                            start_at
                            end_at
                            status
                          }
                        }
                      `,
                      { account_id, course_id }
                    );
                    return course_data;
                  } catch (err) {
                    console.log('error', err);
                  }
                },
                null,
                [course_id]
              );

              return data;
            }),

            paginationModel: hook((ctx) => {
              const course_id = _.get(useRoute(), 'params.id', '');
              const ref = React.useRef({});
              const account_id = ctx.apply('accountModel.getAccountId');
              const pagingModel = React.useMemo(() => {
                const condition = `where: {
                  account_id: {_eq: "${account_id}"}
                  course_id: { _eq: "${course_id}" }
                }`;
                const dataQuery = `
                  b2b_course_room_attendee (${condition}, order_by: {created_at: desc_nulls_last}){
                    member {
                      member_profile {
                        first_name
                        last_name
                        avatar_url
                      }
                    }
                    course {
                      course_rooms {
                        id
                        name
                        photo_url
                        description
                        start_at
                        end_at
                        status
                        link_urls
                        materials
                        join_url
                      }
                    } 
                  }
                `;

                const pagingModel = PaginationModel.fromConfig({
                  Model: B2BCourseModel,
                  dataQuery,
                  aggQuery: `
                    b2b_course_room_attendee_aggregate (${condition}) {
                      aggregate {
                        count
                      }
                    }
                  `,
                  pageSize: 4,
                });
                return pagingModel;
              }, [ref, account_id, course_id]);

              pagingModel.hookState();

              React.useEffect(() => {
                pagingModel.onLoadPageHandler(0);
              }, [pagingModel]);

              const pagination = {
                items: pagingModel.useState('items'),
                page: pagingModel.useState('page'),
                total: pagingModel.useState('total'),
                isLoading: pagingModel.useState('isLoading'),
                pageSize: pagingModel.useState('pageSize'),
                hasPreviousPage: pagingModel.useState('hasPreviousPage'),
                hasNextPage: pagingModel.useState('hasNextPage'),
                hasLoadMore: pagingModel.useState('hasLoadMore'),
                onRefresh: (...args) => pagingModel.onRefresh(...args),
                onPreviousPage: (...args) => pagingModel.onPreviousPageHandler(...args),
                onNextPage: (...args) => pagingModel.onNextPageHandler(...args),
                onLoadPage: (...args) => pagingModel.onLoadPageHandler(...args),
              };

              return pagination;
            }),

            allColumns: hook((ctx) => {
              const locale = i18n.locale;
              const columns = [
                {
                  title: (
                    <div className="text-sub font-semibold text-xs text-center">
                      {ctx.apply('i18n.t', 'ZoomToolMember.Table.number_order')}
                    </div>
                  ),
                  render: (text, record, index) => {
                    return (
                      <div className="text-center">
                        {ctx.get('paginationModel.page') * ctx.get('paginationModel.pageSize') + index + 1}
                      </div>
                    );
                  },
                  width: 70,
                  fixed: 'left',
                },
                {
                  title: (
                    <div className="text-sub font-semibold text-xs">
                      {ctx.apply('i18n.t', 'ZoomToolStudent.Table.student')}
                    </div>
                  ),
                  render: (val) => {
                    return (
                      <div className="identify-container">
                        <div className="table-cell align-top">
                          <Avatar size={40} src={MemberFormatter.avatarUrl(ctx)(_.get(val, 'member', ''))} />
                        </div>

                        <div style={{ verticalAlign: 'middle' }} className="table-cell align-middle pl-3">
                          <div className="text-brandb500 hover:text-brandb500 underline text-xs">
                            {MemberFormatter.fullName(ctx)(_.get(val, 'member', ''))}
                          </div>
                        </div>
                      </div>
                    );
                  },
                  require: true,
                  width: 120,
                },
                ..._.map(_.range(0, 4, 1), (index) => {
                  return {
                    title: () => {
                      const lessonData = ctx.get(`courseLessons.b2b_course_room[${index}]`);
                      const start_at = _.get(lessonData, 'start_at', '');
                      const duration = `${moment(start_at).format(
                        _.get(TIME_HOUR_MINUTE_ML_FORMAT, locale, 'de')
                      )} - ${moment(_.get(lessonData, 'end_at', '')).format(
                        _.get(TIME_HOUR_MINUTE_ML_FORMAT, locale, 'de')
                      )}`;

                      return (
                        <div>
                          <div className="text-center text-sub font-semibold text-xs">Lesson {index + 1}</div>
                          <div className="lesson-item text-sub text-center">
                            {!!_.get(lessonData, 'start_at', '') ? (
                              <div className="text-xs">
                                <div>{moment(start_at).format('dddd')},</div>
                                {moment(start_at).format(_.get(START_TIME_WITHOUT_DAY_OF_WEEK, locale, 'de'))}
                                <div>{duration}</div>
                              </div>
                            ) : (
                              'N/A'
                            )}
                          </div>
                        </div>
                      );
                    },
                    render: (val) => {
                      return <div className="lesson-item text-sub text-center">N/A</div>;
                    },
                  };
                }),
              ];
              return columns;
            }),

            tableName: hook(() => 'tblTeacherCoursesReport'),

            tableColumn: hook((ctx) => {
              const data = ctx.get('paginationModel');
              const tableName = ctx.get('tableName', 'tblTeacherCoursesReport');
              const [tableSettings, $tableSettings] = useLocalStorage(`@UZ::TableSettings::${tableName}`, {
                columns: [],
              });
              const ref = React.useRef({});

              const allColumns = ctx.get('allColumns');

              const columns = _.filter(ctx.get('allColumns'), (col) => {
                const key = _.get(col, 'key');
                if (_.get(tableSettings, 'columns.length')) {
                  return _.get(col, 'required') || _.includes(tableSettings.columns, key);
                }
                return _.get(col, 'defaultSelected', true);
              });
              const selectedColumns = _.map(columns, 'key');
              _.assign(ref.current, { tableSettings, $tableSettings });

              return {
                pagination: {
                  pageSize: data.pageSize,
                  total: data.total,
                  showLessItems: true,
                  onChange: (page) => {
                    data.onLoadPage(page - 1);
                  },
                },
                dataSource: data.items,
                columns,
                allColumns,
                selectedColumns,
                selectColumns: (values) => {
                  ref.current.$tableSettings({ ...ref.current.tableSettings, columns: values });
                },
              };
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
