import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import { renderPropsComposer } from '@vl/mod-utils/PropsComposer';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
import { Row, Col } from 'antd';
import gstyles from '@vl/gstyles';
import CustomTable from '@uz/unitz-components-web/CustomTable';

const View8 = () => {
  return (
    <DIV className="component">
      <CustomTable
        columns={ctx.get('tableColumn.columns')}
        dataSource={ctx.get('tableColumn.dataSource', [])}
        rowKey={(rec) => _.get(rec, 'id')}
        loading={_.get(props, 'isLoading', false)}
        pagination={ctx.get('tableColumn.pagination')}
        onChange={ctx.get('paginationModel.onChange')}
        size="middle"
      />
    </DIV>
  );
};

export default displayName(View8);
